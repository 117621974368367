<template>
  <div id="revenue-user">
    <div class="heading text-start">
      <div class="left-heading"><h2>Division information</h2></div>
    </div>
    <div class="content">
      <ValidationObserver>
        <div class="table-header row">
          <ValidationProvider
            name="Division name, email"
            v-slot="{ errors }"
            class="input-group search"
            rules="max:255"
          >
            <span class="input-group-text mb-2">
              <SearchIcon />
            </span>
            <input
              type="text"
              class="form-control mb-2"
              placeholder="Division name, email"
              v-model="filter.id"
              @input="debouncedQuery"
            />
            <span class="error text-left f-w3">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <div class="table-content">
        <table class="table color-8B9DA5">
          <thead>
            <tr>
              <th scope="col">Division</th>
              <th scope="col">DL email</th>
              <th scope="col">SubDL email</th>
              <th scope="col">Total projects</th>
              <th scope="col">Total PMs</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(division, index) in divisionList" :key="index">
              <td class="pt-3 pb-3">{{ division.divison }}</td>
              <td class="pt-3 pb-3">{{ division.dlEmail }}</td>
              <td class="pt-3 pb-3">{{ division.subDLEmail }}</td>
              <td class="pt-3 pb-3">{{ division.totalProject }}</td>
              <td class="pt-3 pb-3">{{ division.totalPMs }}</td>
              <td>
                <router-link 
                  :to="{ name: 'EditDivision', params: { id: division.id } }"
                >
                  <button class="btn">
                    <EditIcon />
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/Icon/SearchIcon";
import EditIcon from "@/components/Icon/EditIcon";
import * as _ from "lodash";

export default {
  name: "Division",
  components: {
    SearchIcon,
    EditIcon,
  },
  data() {
    return {
      divisionList: [],
      filter: {
        id: null,
      },
    };
  },
  created() {
   this.getDivsionlist() 
  },
  methods: {
    async getDivsionlist() {
      this.$root.$refs.loading.start();
      const params = { order: "DESC" };
      if (this.filter.id !== null && this.filter.id !== "") {
        params.q = this.filter.id;
      } else {
        params.q = undefined;
      }
      if (this.filter.date !== null && this.filter.date !== "") {
        params.date = this.filter.date;
      } else {
        params.date = undefined;
      }
      if (this.filter.user_type !== 1) {
        params.user_type = this.filter.user_type;
      } else {
        params.user_type = undefined;
      }
      this.$store
        .dispatch("dashboard/getListDivision", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.divisionList = response;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    debouncedQuery: _.debounce(function () {
      this.getDivsionlist();
    }, 500),
  },
};
</script>

<style scoped lang='scss'>
@import "@/assets/scss/listproject.scss";
</style>
